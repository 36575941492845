.ant-layout-sider-children{

  .logo {
    height: 28px;
    margin: 16px;
    color: white;

    p {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.5em;
      line-height: 1;
      margin-bottom: 0;
    }
    span {
      font-size: 10px;
      line-height: 1;
      margin-top: -35px;
    }
  }
}

.chat-list {

  .top {
    max-height: 400px;
    overflow: auto;
    margin-bottom: 30px;
    border-bottom: 1px solid #e1e1e1;
  }
  .bottom {

  }
}